import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-33632fdd")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex gap-2" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["onClick"]
_popScopeId()


import { usePaymentMethodListStore } from "@/store/pinia/gws-global-feature/pengaturan-pembayaran/payment-method/usePaymentMethodListStore";
import { usePaymentMethodFormStore } from "@/store/pinia/gws-global-feature/pengaturan-pembayaran/payment-method/usePaymentMethodFormStore";
import { ListItem } from "@/core/types/gws-master/pengaturan-pembayaran/payment-method/ListItem";
import { checkButtonPermission } from "@/core/helpers/helpers-utils";
import { formatDateTime } from "@/core/helpers/date-format";

//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: ["delete-data", "edit-data"],
  setup(__props, { emit: emits }) {

const props = __props

const listStore = usePaymentMethodListStore();
const formStore = usePaymentMethodFormStore();


const handleEditData = (item: ListItem) => {
  const editRow = {
    category: item.category,
    name: item.name,
    code: item.code,
    description: item.description,
    logo: item.logo,
    is_active: item.is_active,
    sort: item.sort,
    short_name: item.short_name,
    fee: item.fee,
    time_limit: item.time_limit,
    atm_step: item.atm_step,
    mbanking_step: item.mbanking_step,
  };
  formStore.setFormData(editRow);
  emits("edit-data", item.id);
};
const handleDeleteData = (event) => {
  emits("delete-data", event);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            type: "index",
            label: "No.",
            "min-width": "20",
            align: "center"
          }),
          _createVNode(_component_el_table_column, {
            prop: "category",
            label: "Kategori",
            "min-width": "100",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Nama ",
            "min-width": "100",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "time_limit",
            label: "Batas Waktu",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.time_limit) + " Jam ", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "fee",
            label: "Biaya Admin",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "description",
            label: "Deskripsi",
            "min-width": "100",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "is_active",
            label: "Status",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", {
                class: _normalizeClass(["d-flex align-items-center justify-content-center", {
              'wrapper-active': scope.row.is_active,
              'wrapper-inactive': !scope.row.is_active,
            }])
              }, _toDisplayString(scope.row.is_active ? "Active" : "Non Active"), 3)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: "Aksi",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                (_unref(checkButtonPermission)('U'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-edit",
                      id: scope.id
                    }, [
                      _createVNode(_component_inline_svg, {
                        src: `media/gws/pencil-alt.svg`,
                        onClick: ($event: any) => (handleEditData(scope.row))
                      }, null, 8, ["src", "onClick"])
                    ], 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (_unref(checkButtonPermission)('D'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn-delete",
                      onClick: ($event: any) => (handleDeleteData(scope.row))
                    }, [
                      _createVNode(_component_inline_svg, { src: `media/gws/trash.svg` }, null, 8, ["src"])
                    ], 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})