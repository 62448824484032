import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-master/pengaturan-pembayaran/payment-method/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const usePaymentMethodFormStore = defineStore({
  id: "paymentMethodFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData() {
      const payload = {
        category: this.formData.category,
        name: this.formData.name,
        code: this.formData.code,
        description: this.formData.description,
        logo: this.formData.logo,
        is_active: this.formData.is_active,
        sort: this.formData.sort,
        short_name: this.formData.short_name,
        fee: this.formData.fee,
        time_limit: this.formData.time_limit,
        atm_step: this.formData.atm_step,
        mbanking_step: this.formData.mbanking_step,
      };
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/payment-method`,
          payload as AxiosRequestConfig
        );
        if (response.status === 201) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      const payload = {
        category: this.formData.category,
        name: this.formData.name,
        code: this.formData.code,
        description: this.formData.description,
        logo: this.formData.logo,
        is_active: this.formData.is_active,
        sort: this.formData.sort,
        short_name: this.formData.short_name,
        fee: this.formData.fee,
        time_limit: this.formData.time_limit,
        atm_step: this.formData.atm_step,
        mbanking_step: this.formData.mbanking_step,
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/payment-method/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );

        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },

    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/payment-method/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        category: data.category || "",
        name: data.name || "",
        code: data.code || "",
        description: data.description || "",
        logo: data.logo || "",
        is_active: data.is_active,
        sort: data.sort || 0,
        short_name: data.short_name || "",
        fee: data.fee || 0,
        time_limit: data.time_limit || 0,
        atm_step: data.atm_step || "",
        mbanking_step: data.mbanking_step || "",
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
  },
});
